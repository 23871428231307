import React, { useReducer, useContext } from "react";
import Contexto from "./Contexto";
import miReducer from "./miReducer";

// Estado inicial
const initialState = {
  isLoggedIn: false, // Estado de si el usuario está logeado
  companyId: null,        
  companyName: null,
  dirEndPoint: null    
};


// Hook personalizado para acceder al contexto
export const useAuth = () => useContext(Contexto);

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(miReducer, initialState);

  // Función para iniciar sesión
  const login = (companyId, companyName) => {
    dispatch({ type: 'LOGIN', payload: { companyId, companyName} });
  };

  // Función para cerrar sesión
  const logout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <Contexto.Provider
    value={{ state, login, logout }}
    >
      {children}
    </Contexto.Provider>
  );
};

export default Provider;
