import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const FindHeader = () => {
  const buscadorRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      // Navegar a la página de resultados con el término de búsqueda como query param
      buscadorRef.current.value = "";
      buscadorRef.current.focus();
      navigate(`/results?q=${encodeURIComponent(searchTerm)}`);
    }
  };

  return (
    <div className="navbar-search-block">
    <form onSubmit={handleSearch} className="form-inline">
      <div className="input-group input-group-sm">
        <input
          ref={buscadorRef}
          className="form-control form-control-navbar"
          type="search"
          placeholder="Buscar cupón"
          aria-label="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="input-group-append">
          <button className="btn btn-navbar" type="submit">
            <i className="fas fa-search" />
          </button>
          <button
            className="btn btn-navbar"
            type="button"
            data-widget="navbar-search" >
            <i className="fas fa-times" />
          </button>
        </div>
      </div>
    </form>
  </div>
  )
}

export default FindHeader