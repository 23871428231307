import React, { useState, useEffect } from "react";
import Mensaje from "../components/widgets/Mensaje";
import { useAuth } from "../context/Provider";

export default function Empresas() {
  const { state } = useAuth();
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    setCompanyName(state.companyName);
  }, []);
  
  return (
    <Mensaje mensaje={companyName} />
  );
}
