import React from "react";
import './App.css'

import RouterPublic from "./routers/RouterPublic";
import Provider from "./context/Provider";

const App = () => {
  return (
    <>
      <Provider>
        <RouterPublic />
      </Provider>
    </>
  );
};

export default App;
