import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Provider";

export default function Aside() {
  const navegacion = useNavigate();
  const { state } = useAuth();
  const [companyName] = useState(state.companyName);
  const { logout } = useAuth();
  const Logout = (event) => {
    event.preventDefault(); // Previene la navegación del enlace
    navegacion("/login", { replace: true });
    logout();
    localStorage.clear();
  };

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* Brand Logo */}
      <Link to="" className="brand-link">
        <img
          src="img/cupon.png"
          alt="CUPONES Logo"
          className="brand-image img-circle elevation-0"
          style={{ opacity: ".8" }}
        />
        <span className="brand-text font-weight-light">CUPONES</span>
      </Link>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src="img/company.png"
              className="img-circle elevation-0"
              alt="User Image"
            />
          </div>
          <div className="info">
            <Link to="/empresas" className="d-block" title={companyName}>
              {companyName}
            </Link>
          </div>
        </div>
        {/* SidebarSearch Form */}
        {/*<div className="form-inline">
          <div className="input-group" data-widget="sidebar-search">
            <input
              className="form-control form-control-sidebar"
              type="search"
              placeholder="Buscar Cupón"
              aria-label="Search"
            />
            <div className="input-group-append">
              <button className="btn btn-sidebar">
                <i className="fas fa-search fa-fw" />
              </button>
            </div>
          </div>
        </div>*/}
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
            <li className="nav-item menu-open">
              <li className="nav-header">MENU</li>
              <li className="nav-item">
                <NavLink to="/" className="nav-link">
                  <i className="far fas fa-home nav-icon" />
                  <p>Inicio</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/empresas" className="nav-link">
                  <i className="far fa-building nav-icon" />
                  <p>Mi empresa</p>
                </NavLink>
              </li>
            </li>
            <li className="nav-header">CUPONES</li>
            <li className="nav-item">
              <NavLink to="/todos" className="nav-link">
                <i className="nav-icon fas fa-tag text-success" />
                <p>Todos</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/disponibles" className="nav-link">
                <i className="nav-icon fas fa-tag text-info" />
                <p>Disponibles</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/consumidos" className="nav-link">
                <i className="nav-icon fas fa-tag text-danger" />
                <p>Consumidos</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/addcupon" className="nav-link">
                <i className="nav-icon fas fa-plus text-primary" />
                <p>Generar</p>
              </NavLink>
            </li>
            <li className="nav-header">DOCUMENTACION</li>
            <li className="nav-item">
              <NavLink to="/ayuda" className="nav-link">
                <i className="nav-icon fas fa-file" />
                <p>Ayuda</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/salir" className="nav-link" onClick={Logout}>
                <i className="nav-icon fas fa-window-close" />
                <p>Salir</p>
              </NavLink>
            </li>
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
}
