import React from "react";

const AyudaPDF = () => {
  return (
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div className="card">
            <div className="card-header border-0">
              <h5>Manual de Usuario</h5>
              <a
                href={`${process.env.PUBLIC_URL}/docs/manual.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Descargar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AyudaPDF;
