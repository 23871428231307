import React from 'react'

const Contact = () => {
  return (
    <div>
    <div class="row">
      <div class="col-lg-12">
        <div className="card">
          <div className="card-header border-0">
            <h3 className="card-title">Este módulo se encuentra en desarrollo...</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Contact