import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/Provider";

const Notification = () => {
  const { state } = useAuth();
  const [notificationCount, SetnotificationCount] = useState("");
  const [showNotification, SetshowNotification] = useState(false);
  const [totalCuponesDisponibles, setTotalCuponesDisponibles] = useState(false);
  const dirEndPoint = process.env.REACT_APP_BACKEND;
  const token = process.env.REACT_APP_TOKEN;

  const fetchInfo = async () => {

    const companyId = state.companyId;

    try {

      const resp = await axios.get(
        `${dirEndPoint}coupons/totals?access-token=${token}`,
        {
          params: {
            company_id: companyId
          },
        }
      );

      setTotalCuponesDisponibles(resp.data.totalAvailable);

      SetnotificationCount(1);
         resp.data.totalAvailable < 50
        ? SetshowNotification(true)
        : SetshowNotification(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchInfo();
  }, [showNotification]);

  const estiloDiv = {
    width: "250px",
    wordWrap: "break-word",
    whiteSpace: "normal",
  };

  const manejarClick = () => {
    localStorage.setItem("mensajeOculto", true);
  };

  return (
    <li className="nav-item dropdown" onClick={manejarClick}>
      <a className="nav-link" data-toggle="dropdown">
        <i className="far fa-bell" />
        {showNotification ? (
          <span className="badge badge-danger navbar-badge">
            {notificationCount}
          </span>
        ) : null}
      </a>
      {showNotification ? (
        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span className="dropdown-item dropdown-header bg-danger">Información</span>
          <div className="dropdown-divider" />
          <Link to="/disponibles" className="dropdown-item">
            <div style={estiloDiv}>
              <i className="fas fa-tag mr-2" />

              {totalCuponesDisponibles > 0 ? (
                <>Solo quedan {totalCuponesDisponibles} cupones disponibles</>
              ) : (
                <>No quedan cupones disponibles</>
              )}

              <span className="float-right text-muted text-sm"> Mostrar</span>
            </div>
          </Link>
          <div className="dropdown-divider" />
          {/*
          <a href="#" className="dropdown-item dropdown-footer">
            See All Notifications
          </a>
          */}
        </div>
      ) : null}
    </li>
  );
};

export default Notification;
