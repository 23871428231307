import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Mensaje from "../../components/widgets/Mensaje";
import Loading from "../../components/widgets/Loading";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useAuth } from "../../context/Provider";

const Consumidos = () => {
  const { state } = useAuth();
  const tableRef = useRef("table");
  let iterator = 1;
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showAll, setShowAll] = useState(false); // Estado para mostrar todos
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState(false);
  const [totalCupones, setTotalCupones] = useState(0);
  const dirEndPoint = process.env.REACT_APP_BACKEND;
  const token = process.env.REACT_APP_TOKEN;
  // isArchived determina 0: Disponibles, 1: Consumidos, null: Todo
  const isArchived = 1;

  // Función para obtener los datos paginados
  const fetchItems = async (page) => {
    setLoading(true);

    const companyId = state.companyId;

    try {
      const resp = await axios.get(
        `${dirEndPoint}coupons?access-token=${token}`,
        {
          params: {
            page: page,
            pageSize: limit,
            is_archived: isArchived,
            company_id: companyId,
          },
        }
      );

      setTotalCupones(resp.data.total);
      setItems(resp.data.data);
      setTotalPages(resp.data.totalPages);
      setCurrentPage(resp.data.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItems(currentPage);
  }, [currentPage, limit]);

  // Manejar la paginación
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Generar los botones de números de página
  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li className={i == currentPage ? "page-item active" : "page-item "}>
          <a className="page-link" key={i} onClick={() => handlePageChange(i)}>
            {i}
          </a>
        </li>
      );
    }
    return pageNumbers;
  };

  function formatDate(isoDate) {
    let jsDate = new Date(isoDate);

    let year = jsDate.getFullYear();
    let month = (jsDate.getMonth() + 1).toString().padStart(2, "0");
    let day = jsDate.getDate().toString().padStart(2, "0");
    let hours = jsDate.getHours().toString().padStart(2, "0");
    let minutes = jsDate.getMinutes().toString().padStart(2, "0");
    let seconds = jsDate.getSeconds().toString().padStart(2, "0");

    // Formato final: DD-MM-YYYY HH:MM:SS
    let formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }

  const generatePDF = () => {
    const doc = new jsPDF();

    // Título del PDF
    doc.text("Cupones Consumidos", 14, 16);

    // Obtén la tabla del DOM usando la referencia
    const table = tableRef.current;

    // Convierte la tabla a PDF usando autoTable
    doc.autoTable({
      html: table,
      startY: 20, // Posición donde comenzará la tabla en el PDF
    });

    // Guardar el PDF
    doc.save("Cupones Consumidos.pdf"); // Guardar el PDF
  };

  // Cambiar entre paginación y mostrar todo
  const toggleShowAll = () => {
    setShowAll(!showAll);
    if (!showAll) {
      setCurrentPage(1); // Reiniciar a la primera página si se desactiva "Mostrar Todo"
      setLimit(5000);
    } else {
      setLimit(20);
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          {totalCupones != 0 ? (
            <div className="row">
              <div className="col-md-10  offset-md-1">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">
                      <i class="fas fa-tag text-danger"></i>
                      &nbsp;Listado de cupones (
                      <span className="text-muted">Total: {totalCupones}</span>)
                    </h3>
                    <div class="card-tools">
                      <ul class="nav nav-pills ml-auto">
                        <li class="nav-item">
                          <a
                            class="btn btn-sm btn-tool"
                            data-toggle="tab"
                            onClick={toggleShowAll}
                          >
                            {!showAll ? "Ver todos" : "Ver por páginas"}
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            href="#"
                            class="btn btn-sm btn-tool"
                            title="Exportar a PDF los cupones listados en pantalla"
                          >
                            <i
                              class="fas fa-download"
                              onClick={generatePDF}
                            ></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* /.card-header */}

                  <div className="card-body">
                    <table
                      ref={tableRef}
                      border="1"
                      cellPadding="10"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th style={{ width: 10 }}>#</th>
                          <th>Cupón</th>
                          <th style={{ width: 250 }}>Fecha de creación</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item) => (
                          <tr key={item.id}>
                            <td>{iterator++}.</td>
                            <td>{item.coupon}</td>
                            <td>{formatDate(item.created_at)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/* Mostrar el total de páginas */}
                  {!showAll && totalCupones > limit && (
                    <div className="card-footer clearfix">
                      {/* Mostrar paginación */}
                      <ul className="pagination pagination-sm m-0 float-right">
                        <li className="page-item">
                          <a
                            className="page-link"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                          >
                            «
                          </a>
                        </li>
                        {/* Botones de número de página */}
                        {renderPageNumbers()}

                        <li className="page-item">
                          <a
                            className="page-link"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          >
                            »
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            Mensaje({ mensaje: "La empresa no tiene cupones consumidos" })
          )}
        </div>
      )}
    </div>
  );
};

export default Consumidos;
