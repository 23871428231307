const Directions = new Map([
  ["contact", "Contacto"],
  ["todos", "Todos los Cupones"],
  ["disponibles", "Cupones Disponibles"],
  ["consumidos", "Cupones Consumidos"],
  ["addcupon", "Generar Cupones"],
  ["editarcupon", "Editar Cupón"],
  ["empresas", "Mi Empresa"],
  ["results", "Buscador"],
  ["ayuda", "Ayuda"],
  ["home", "Inicio"],
  ["", "Inicio"],
]);

export default Directions;
