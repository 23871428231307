import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/Provider";

const RoutePublic = ({ children }) => {
  const { state } = useAuth(); 
  return !state.isLoggedIn  ? children : <Navigate to="/home" />;
};

export default RoutePublic;
