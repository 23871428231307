import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "../layouts/Layout";
import Home from "../pages/Home";
import Contact from "../pages/Contact";
import Disponibles from "../pages/cupones/Disponibles";
import Consumidos from "../pages/cupones/Consumidos";
import Ayuda from "../pages/Ayuda";
import Empresas from "../pages/Empresas";
import Cupon from "../pages/cupones/Cupon";
import Todos from "../pages/cupones/Todos";
import AddCupon from "../pages/cupones/AddCupon";
import Results from "../pages/Results";

const RouterPrivate = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/empresas" element={<Empresas />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/todos" element={<Todos />} />
        <Route path="/disponibles" element={<Disponibles />} />
        <Route path="/consumidos" element={<Consumidos />} />
        <Route path="/addcupon" element={<AddCupon />} />
        <Route path="/results" element={<Results />} />
        <Route path="/cupon/:id" element={<Cupon />} />
        <Route path="/ayuda" element={<Ayuda />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

export default RouterPrivate;
