import React from "react";
import { Link, useLocation } from "react-router-dom";
import Directions from "./Directions";

function Breadcrumb() {
  const location = useLocation();
  const crumbs = location.pathname.split("/");
  return (
  <>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h4 className="m-0">{crumbs[1] === "" ? `Inicio` : Directions.get(crumbs[1])}</h4>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/"> Inicio </Link>
                </li>
                <li className="breadcrumb-item active">{Directions.get(crumbs[1])}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
  </>
  );
}

export default Breadcrumb;
