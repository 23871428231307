
const miReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        isLoggedIn: true,  // Cambiamos a "logueado"
        companyId: action.payload.companyId, 
        companyName: action.payload.companyName 
      };
    case 'LOGOUT':
      return {
        isLoggedIn: false,  // Cambiamos a "no logueado"
        companyId: null,        
        companyName: null
      };
    default:
      return state;
  }
};

export default miReducer;
