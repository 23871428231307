import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/Login";
import RouterPrivate from "./RouterPrivate";
import RoutePublic from "./RoutePublic";
import RoutePrivate from "./RoutePrivate";

const RouterPublic = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <RoutePublic>
            <Login />
          </RoutePublic>
        }
      />
      <Route
        path="/*"
        element={
          <RoutePrivate>
            <RouterPrivate />
          </RoutePrivate>
        }
      />
    </Routes>
  );
};

export default RouterPublic;
