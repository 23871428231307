import React from "react";
import AyudaPDF from "../components/widgets/AyudaPDF";

export default function Ayuda() {
  return (
    <>
      <AyudaPDF/>
    </>
  );
}
