import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import { useAuth } from "../context/Provider";

const Results = () => {
  const { state } = useAuth();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setsearchTerm] = useState(null);
  let iterator = 1;
  const location = useLocation();
  const dirEndPoint = process.env.REACT_APP_BACKEND;
  const token = process.env.REACT_APP_TOKEN;

  useEffect(() => {
    const fetchResults = async () => {

      const companyId = state.companyId;

      // Función para obtener el término de búsqueda desde la URL
      const getSearchTerm = () => {
        const params = new URLSearchParams(location.search);
        setsearchTerm(params.get("q") || "");
        return params.get("q") || "";
      };

      const searchTerm = getSearchTerm();

      try {
        const resp = await axios.get(
          `${dirEndPoint}coupons?access-token=${token}`,
          {
            params: {
              coupon: searchTerm.trim(),
              company_id: companyId,
            },
          }
        );

        setItems(resp.data.data);
      } catch (error) {
        console.error("Error al obtener los resultados:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [location.search]);

  function formatDate(isoDate) {
    let jsDate = new Date(isoDate);

    let year = jsDate.getFullYear();
    let month = (jsDate.getMonth() + 1).toString().padStart(2, "0");
    let day = jsDate.getDate().toString().padStart(2, "0");
    let hours = jsDate.getHours().toString().padStart(2, "0");
    let minutes = jsDate.getMinutes().toString().padStart(2, "0");
    let seconds = jsDate.getSeconds().toString().padStart(2, "0");

    // Formato final: DD-MM-YYYY HH:MM:SS
    let formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }

  function getEstado(estado) {
    return estado != 1 ? "DISPONIBLE" : "CONSUMIDO";
  }

  return (
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div className="card">
            <div className="card-header border-0">
              <div style={{ padding: "20px" }}>
                <h2>Resultados de la Búsqueda</h2>
                <Link to="/">← Volver a Buscar</Link>
                <p>
                  Buscando: <strong>{searchTerm}</strong>
                </p>

                {loading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                  </div>
                ) : items.length > 0 ? (
                  <table
                    border="1"
                    cellPadding="10"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th style={{ width: 10 }}>#</th>
                        <th>Cupón</th>
                        <th>Estado</th>
                        <th style={{ width: 250 }}>Fecha de creación</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item) => (
                        <tr key={item.id}>
                          <td>{iterator++}.</td>
                          <td>{item.coupon}</td>
                          <td>{getEstado(item.is_archived)}</td>
                          <td>{formatDate(item.created_at)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No se encontraron resultados para "{searchTerm}".</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
