import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingSpinner from "../components/widgets/LoadingSpinner";
import { useAuth } from "../context/Provider";

export default function Login() {
  const { login } = useAuth();
  const navegacion = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const emailRef = useRef(null);
  const dirEndPoint = process.env.REACT_APP_BACKEND;
  const token = process.env.REACT_APP_TOKEN;

  const validate = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validar el email
    if (!email) {
      errors.email = "El email es requerido";
    } else if (!emailRegex.test(email)) {
      errors.email = "El email no es válido";
    }

    // Validar la contraseña
    if (!password) {
      errors.password = "La contraseña es requerida";
    } else if (password.length < 6) {
      errors.password = "La contraseña debe tener al menos 6 caracteres";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      setLoading(true);

      try {
        const response = await axios.post(
          `${dirEndPoint}companies/login?access-token=${token}`,
          {
            email: email,
            password: password,
          }
        );

        login(
          response.data.data.id,
          response.data.data.name
        );
        
        navegacion("/home", { replace: true });
      } catch (err) {
        console.error("Error en la solicitud :", err);
        setErrors({ password: "Usuario o contraseña incorrectos." });
      } finally {
        setLoading(false);
      }
    }

    setEmail("");
    setPassword("");
    emailRef.current.focus();
  };

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const handleClick = (event) => {
    event.preventDefault(); // Previene la navegación del enlace
    alert('¡Este módulo se encuentra en desarrollo...!');
  };

  return (
    <div>
      <body class="hold-transition login-page">
        <div className="login-box">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <div className="image">
                <img
                  src="img/cupon.png"
                  className="img-circle elevation-0"
                  alt="Bienvenido al sistema de gestión de cupones"
                  width="32"
                />
              </div>
              <div className="info h4">Cupones Corporate</div>
            </div>
            <div className="card-body">
              <p className="login-box-msg">
                {" "}
                {loading ? <LoadingSpinner /> : "Inicio de sesión"}
              </p>

              <form onSubmit={handleSubmit}>
                <div className="input-group mb-4">
                  <input
                    ref={emailRef}
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Correo"
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope" />
                    </div>
                  </div>
                  <div className="input-group">
                    {errors.email && (
                      <p style={{ color: "red" }}>{errors.email}</p>
                    )}
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                  <div className="input-group">
                    {errors.password && (
                      <p style={{ color: "red" }}>{errors.password}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    <div className="icheck-primary">
                      <input type="checkbox" id="remember" />
                      <label htmlFor="remember">&nbsp;Recordarme</label>
                    </div>
                  </div>
                  <div className="col-4">
                    <button type="submit" className="btn btn-primary btn-block">
                      {loading ? <LoadingSpinner /> : "Iniciar"}
                    </button>
                  </div>
                </div>
                <p className="mb-1">
                  <a href="#" onClick={handleClick}>Olvidé mi contraseña</a>
                </p>
                <p className="mb-0">
                  <a href="#" onClick={handleClick} className="text-center">
                    Registrarse
                  </a>
                </p>
              </form>

              <div className="row">
                <div className="col-12">
                  <footer className="main-footer1 text-center">
                    <br />
                    <br />
                    <strong>
                      Copyright © 2024 <a href="https://www.dorsia.es/">WmClinics</a>.
                    </strong>{" "}
                    <br />
                    Todos los derechos reservados.
                    <div className="float-right d-none d-sm-inline-block">
                      <b>Version</b> 1.0
                    </div>
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}
