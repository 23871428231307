import React from "react";
import { Outlet } from "react-router-dom";
import Breadcrumb from "../breadcrumb/Breadcrumb";

export default function Content() {
  return (
    <div className="content-wrapper">
      <div class="container-fluid">
        <Breadcrumb />
        <Outlet />
      </div>
    </div>
  );
}
