import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/Provider";

const RoutePrivate = ({ children }) => {
  const { state } = useAuth(); 
  return state.isLoggedIn ? children : <Navigate to="/login" />;
};

export default RoutePrivate;
