import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Loading from "../components/widgets/Loading";
import { useAuth } from "../context/Provider";

export default function Home() {
  const { state } = useAuth();
  const [loading, setLoading] = useState(false);
  const [totalCupones, setTotalCupones] = useState(0);
  const [totalCuponesDisponibles, setTotalCuponesDisponibles] = useState(0);
  const [totalCuponesConsumidos, setTotalCuponesConsumidos] = useState(0);
  const dirEndPoint = process.env.REACT_APP_BACKEND;
  const token = process.env.REACT_APP_TOKEN;

  const fetchInfo = async () => {
    setLoading(true);

    const companyId = state.companyId;

    try {

      const resp = await axios.get(
        `${dirEndPoint}coupons/totals?access-token=${token}`,
        {
          params: {
            company_id: companyId
          },
        }
      );
      setTotalCupones(resp.data.total);
      setTotalCuponesConsumidos(resp.data.totalConsumed);
      setTotalCuponesDisponibles(resp.data.totalAvailable);

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4 col-6">
                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>
                      {totalCupones}
                      <sup style={{ fontSize: 20 }}></sup>
                    </h3>
                    <p>Total de Cupones </p>
                  </div>
                  <div className="icon">
                    <i className="fa fa-tag" />
                  </div>
                  <Link to="/todos" className="small-box-footer">
                    Más Información <i className="fa fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3> {totalCuponesDisponibles}</h3>
                    <p>Cupones Disponibles</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-tag" />
                  </div>
                  <Link to="/disponibles" className="small-box-footer">
                    Más Información <i className="fa fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>{totalCuponesConsumidos}</h3>
                    <p>Cupones Consumidos</p>
                  </div>
                  <div className="icon">
                    <i className="fa fa-tag" />
                  </div>
                  <Link to="/consumidos" className="small-box-footer">
                    Más Información <i className="fa fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div classname="row">
              <div classname="col-lg-4 col-6">
                <Link to="addcupon" class="btn btn-app bg-primary">
                  <span class="badge bg-danger">Nuevo</span>
                  <i class="fas fa-plus"></i> Adicionar Cupones
                </Link>
                <br /> <br />
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
