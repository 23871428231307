import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import FindHeader from "./FindHeader";
import Notification from "./Notification";
import { useAuth } from "../../context/Provider";

export default function Header() {
  const navegacion = useNavigate();
  const { logout } = useAuth();
  const Logout = () => {
    navegacion("/login", { replace: true });
    logout();
    localStorage.clear();
  };
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" role="button">
            <i className="fas fa-bars" />
          </a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <NavLink to="/" className="nav-link">
            Inicio
          </NavLink>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <NavLink to="/contact" className="nav-link">
            Contacto
          </NavLink>
        </li>
      </ul>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        {/* Navbar Search */}

        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="navbar-search"
            href="#"
            role="button"
          >
            <i className="fas fa-search" />
          </a>
          <FindHeader />
        </li>

        <Notification/>
      
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="fullscreen"
            href="#"
            role="button"
          >
            <i className="fas fa-expand-arrows-alt" />
          </a>
        </li>

        <li className="nav-item d-none d-sm-inline-block">
          <button className="btn btn-navbar" onClick={Logout}>
            Salir
          </button>
        </li>
      </ul>
    </nav>
  );
}
