import React from "react";
import Header from "../components/header/Header";
import Aside from "../components/aside/Aside";
import Content from "../components/content/Content";
import Footer from "../components/footer/Footer";

const Layout = () => {
  return (
    <>
      <div class="wrapper">
        <Header />
        <Aside />
        <Content />
        <Footer />
      </div>
    </>
  );
};

export default Layout;
